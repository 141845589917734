<template>
  <div>
    <!--    Loader-->
    <!--    <div class="vld-parent">-->
    <!--      <loading :active.sync="isLoading"-->
    <!--               :is-full-page="true"-->
    <!--               :color="'#00b8ee'"-->
    <!--               blur="0"></loading>-->
    <!--    </div>-->
    <Navigation
      :show-language-switcher="true"
      :show-user-menu="false"
      :showLogin="true"
    />

    <!--      Forms-->
    <div v-if="get_lead_forms.step_meta" class="max-w-3xl mx-auto min-h-screen">
      <div class="mt-10 pb-10">
        <Form
          v-for="(step, index) in get_lead_forms.step_meta"
          v-if="index === $store.getters.show_next_step"
          :id="index"
          :key="index"
          :demo-form="true"
          :forms="get_lead_forms.actions.GET.fields"
          :numberOfSteps="get_lead_forms.step_meta.length - 1"
          :step="step"
          classes="shadow rounded-md"
          form-type="Lead"
        />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import Form from "@/views/register/Form";
import { mapGetters } from "vuex";

export default {
  name: "Lead",
  components: {
    Footer,
    Navigation,
    Form,
  },
  computed: {
    ...mapGetters(["get_lead_forms"]),
  },
  created() {
    this.$store.dispatch("set_lead_forms");
  },
  beforeCreate() {
    if (!localStorage.getItem("step")) {
      localStorage.setItem("step", "0");
    }
  },
};
</script>